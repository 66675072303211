[data-sidebar-style="overlay"] {
	&[data-layout="vertical"]{
		.deznav{
			.metismenu{
				ul a{
					padding-left: 5.25rem;
					@include respond ('phone'){
						padding-left:4rem;
					}
				}
				&>li{
					&>a{
						padding: 20px 30px;
						font-size: 18px;
						i{
							font-size:1.5rem;
							height:auto;
							width:auto;
							line-height:1;
						}
						&:before{
							position: absolute;
							height: 100%;
							width: 0px;
							top: 0;
							left: 0;
							border-radius: 0px 10px 10px 0;
							content: "";
							background: var(--primary);
							@include transitionFast;
						}
						@include respond ('phone'){
							padding:10px 30px;
							font-size:16px;
							i{
								font-size:1.2rem;
							}
						}
					}
					&.mm-active{
						&>a{
							&:before{
								width:11px;
							}
						}
					}
				}
			}
		}
	}
    .deznav {
        left: -100%;
        @at-root [direction="rtl"]#{&} {
            left: auto;
            right: -100%;
        }
    }
    .content-body {
        margin-left: 0;
    }
    .nav-header {
        position: absolute;
        .hamburger.is-active {
            left: 0;
            .line {
                background-color: var(--primary);
            }
        }
    }
    .menu-toggle {
        .nav-header {
            position: absolute;
            left: auto;
        }
        .deznav {
            left: 0;
            @at-root [direction="rtl"]#{&} {
                left: auto;
                right: 0;
            }
        }
    }
    .footer {
        padding-left: 0;
    }
}

[data-sidebar-style="overlay"][data-header-position="fixed"] {
    .nav-header {
        position: fixed;
    }
}

[data-sidebar-position="fixed"][data-header-position="fixed"] {
    .nav-header {
        position: fixed;
    }
}