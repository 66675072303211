// html[dir="rtl"] {

    //content body
    [direction="rtl"] {
        .select2-container--default .select2-selection--single .select2-selection__arrow{
			left:15px;
			right:auto;
		}
        .input-group > .bootstrap-select:not(:first-child) .dropdown-toggle {
            
            border-radius: 0.75rem 0   0 0.75rem;
        }
		.list-group{
			padding-left: 0;
		}
		.form-check-input{
		    margin-left: -1.25rem;
			margin-right: inherit;
		}
		.form-check-inline .form-check-input{
			margin-right: 0;
			margin-left: 10px;
		}
		.radio input, 
		.radio-inline, 
		.checkbox input, 
		.checkbox-inline input{
		    margin-left: 0;
			margin-right: 0;
		}
        .content-body {
            margin-right:  21.563rem;
            margin-left: auto;
			@include respond ('laptop'){
				margin-right:17rem;
			}
			@at-root [data-sidebar-style="modern"]#{&} {
				margin-right: 9.375rem;
			}
            .page-titles {
                text-align: right;
            }
        }
		.doctor-info-details .media-body span i,
		.recovered-chart-deta .col [class*="bg-"]{
			margin-right:0;
			margin-left:10px;
		}
		.patients-chart-deta .col,
		.patients-chart-deta .col [class*="bg-"],
		.recovered-chart-deta .col{
			margin-right:0;
			margin-left:15px;
		}
		
		.best-doctor .timeline .timeline-panel .media .number{
			left: auto;
			right: -13px;
		}
		.doctor-info-details .media i{
		    right: 0;
		    left: -15px;
		}
		.review-table .disease{
		    border-left:0;
		    border-right: 1px solid #eee;
			padding-left: 0;
			padding-right: 20px;
		}
		.apexcharts-legend-text{
			margin:4px;
		}
		.doctor-info-details .media-body{
		    padding-left: 0;
		    padding-right: 40px;
		}
		.custom-control{
		    margin-left: 0;
		}
		.review-tab.nav-pills li:first-child a.nav-link {
			border-radius: 0 0.75rem 0 0;
		}
		.review-tab.nav-pills li:last-child a.nav-link {
			border-radius: 0.75rem 0 0 0;
		}
		.form-head .btn i{
			margin-left: 5px;
			margin-right: 0;
		}
		.iconbox{
			padding-left: 0;
			padding-right: 70px;
			i{
				left: auto;
				right: 0;
			}
		}
		
		.table {
			&.tr-rounded{
				tr{
					td,th{
						&:first-child{
							border-radius:0 $radius $radius 0;
						}
						&:last-child{
							border-radius:$radius 0 0 $radius;
						}
					}
				}
			}
		}
		
		.custom-switch.toggle-switch.text-right{
			   padding-left: 48px;
			padding-right: 0;
		}
		.toggle-switch.text-right .custom-control-label:before{
			right: auto !important;
            left: -47px;
		}
		.toggle-switch.text-right .custom-control-label:after{
			right: auto !important;
            left: -28px;
		}
		.toggle-switch.text-right .custom-control-input:checked ~ .custom-control-label:after {
			left: -62px;
            right: auto !important;
		}
		.check-switch {
			padding-right: 40px;
			.custom-control-label:after,.custom-control-label:before {
				right: -35px !important;
			}
		}
		.bar-chart{
			.apexcharts-yaxis{
				transform: translatex(101%);
			}
		}
		
		.detault-daterange .input-group-text{
			padding: 0.532rem 0.75rem;
			padding-right:auto;
			padding-left:0;
		}
		.form-wrapper .input-group .form-control{
			text-align:left;
		}
		
		.timeline-chart{
			.apexcharts-yaxis{
				transform:translateX(0);
			}
		}		
		.card-table td,
		.card-table th{
			&:first-child{
				padding-right:30px;
				padding-left:10px;
			}
			&:last-child{
				padding-left:30px;
				padding-right:10px;
			}
		}
		.chatbox .img_cont{
			margin-right:0px;
			margin-left:10px;
		}
		
		.profile-tab .nav-item .nav-link{
			margin-right:0;
			margin-left:30px;
			@include respond ('phone'){
				margin-left:0px;
			}
		}
		
        .widget-timeline .timeline > li > .timeline-panel{
            margin-left: 0px;
            margin-right: 40px;
        }
        
        .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel{
            padding: 5px 15px 5px 10px;
            border-width: 0px 5px 0px 0px;
        }
        
        .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel:after{
                border: 0;
                right: -9px;
                width: 7px;
                left:auto;
                height: 7px;
        }
        .widget-timeline .timeline > li > .timeline-badge {
            left: auto;
            right: 0px;
        }
        
        .widget-timeline .timeline > li > .timeline-panel:after{
            left: auto;
            right: -5px;
        }
        
        .chatbox .msg_cotainer{
            margin-left: 0;
            margin-right: 10px;
			border-radius: 1.375rem 0 1.375rem 1.375rem;
			&:after{
				left:auto;
				right: -10px;
				transform: rotate(-90deg);
			}
        }
		.chatbox .type_msg .input-group .input-group-append .btn{
			    border-top-right-radius: 38px !important;
				border-bottom-right-radius: 38px !important;
		}
        .chatbox .msg_cotainer_send{
			margin-right: 0px;
			margin-left: 10px;
			border-radius: 0 6px 6px 6px;
			&:after{
				right: auto;
				left: -10px;
				transform: rotate(90deg);
			}
		}
		
        .comment-review{
			right:auto;
			left:0;
		}
        .input-group > .bootstrap-select:not(:first-child) .dropdown-toggle{
                 border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: $radius;
            border-bottom-left-radius: $radius;
        }
        .input-group > .bootstrap-select:not(:last-child) .dropdown-toggle{
            border-top-right-radius: $radius;
                border-bottom-right-radius:$radius;
                border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        
        .breadcrumb-item + .breadcrumb-item {
            padding-right: 0.5rem;
            padding-left: 0;
        }
        .breadcrumb-item + .breadcrumb-item::before{
                padding-right: 0;
                padding-left: 0.5rem;
        }
        .chatbox .chatbox-close{
                left: 340px;
                right:auto;
        }
		.message-search-area .input-group-text{
			padding-left:25px;
			padding-right:10px;
			border-left: 1px solid $border-color;
			border-right: 0;
			border-radius:2rem;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		.message-search-area .form-control{
			border-right: 1px solid $border-color;
			border-left: 0;
			border-radius:2rem;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		.card-tabs .nav-tabs.style-1 .nav-item .nav-link{
			padding-right: 0;
			padding-left: 25px;
		}
		.chat-box .message-sent p{
			 border-radius: 20px 20px 20px 0;
		}
		.chat-box .message-received p{
			border-radius: 20px 0 20px 20px;
		}
		.mail-list .list-group-item i{
			padding-right:0;
			padding-left: 0.625rem;
		}
		.dataTables_wrapper .dataTables_filter input {
			margin-left: 0;
			margin-right: 0.5em;
		}
		
        &[data-layout="horizontal"] {
            .content-body {
                margin-right: 0;
            }
			.deznav .metismenu li li .has-arrow:after{
				-webkit-transform: rotate(-4deg) translateY(-50%);
				transform: rotate(-45deg) translateY(-50%);
			}
        }

        &[data-sidebar-style="mini"]:not([data-layout="horizontal"]) {
            .content-body {
                margin-right: 5rem;
            }
        }

        &[data-sidebar-style="compact"]:not([data-layout="horizontal"]) {
            .content-body {
                margin-right: 15rem;
            }
        }

        
		
        &[data-sidebar-style="overlay"] {
            .content-body {
                margin-right: 0;
            }
        }
		#external-events .external-event:before{
			margin-right: 0;
			margin-left: .9rem;
		}
		.post-input a i{
		    margin-left: 0;
		    margin-right: 0;
		}
		.deznav .metismenu .has-arrow:after{
		    -webkit-transform: rotate(-45deg) translateY(-50%);
			transform: rotate(-45deg) translateY(-50%);
		}
		.deznav .metismenu .has-arrow[aria-expanded=true]:after, 
		.deznav .metismenu .mm-active > .has-arrow:after {
			-webkit-transform: rotate(-135deg) translateY(-50%);
			transform: rotate(-135deg) 
		}
		&[data-layout="horizontal"]{
			.deznav .metismenu .has-arrow:after{
				-webkit-transform: rotate(225deg) translateY(-50%);
				transform: rotate(225deg) translateY(-50%);
			}
		}
		.chatbox{
		    left: -500px;
		    right: auto;
		}
		.chatbox.active{
		    left: 0;
		    right: auto;
		}
		
		@media only screen and (max-width: 575px){
			.best-doctor .timeline .timeline-panel .media {
				float: right;
				margin-right: 0 !important;
				margin-left: 15px !important;
			}
		}
		
		.default-select{
			&.style-1{
				.btn{
					&:after{
						margin-left: 0;
						margin-right:0.5em;
					}
				}
			}
		}
        .pagination .page-indicator {
            transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
        }
		/* ===== Light gallery ===== */
		.lg-outer.lg-visible{
			direction: ltr;
		}
		
		.chart-point .chart-point-list {
			margin: 0;
			padding-right: 20px;
		}
		
		.sidebar-right .ps--active-x > .ps__rail-x {
		  display: none;
		}
		.form-wizard .nav-wizard li .nav-link:after{
			right:50%;
			left:auto;
		}
		.dtp > .dtp-content{
			right: 50%;
			left:auto;
		}
		.modal-header .close {
			margin: 0;
			left: 0;
			top: 0px;
			right: auto;
		}
		.input-group-prepend .btn + .btn {
			border-radius: 0 !important;
		}
		.form-control + .input-group-append .btn:first-child {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
		//.input-group > .custom-file:not(:first-child) .custom-file-label {
		//	border-top-right-radius: 0;
		//	border-bottom-right-radius: 0;
		//	border-top-left-radius: 0.75rem;
		//	border-bottom-left-radius: 0.75rem;
		//}
		
		.input-group > .custom-file:not(:first-child) .custom-file-label {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: $radius;
			border-top-left-radius: $radius;
		}
		
		.custom-file-label::after {
			border-radius: $radius 0 0 $radius;
		}
		.input-group > .custom-file:not(:last-child) .custom-file-label {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-top-right-radius: $radius;
			border-bottom-right-radius: $radius;
		}
		.input-group > .custom-file:not(:last-child) .custom-file-label::after{
			border-radius:0;
		}
		.weather-btn{
			margin-left:20px;
		}
		.coin-holding{
			&:after{
				background: -moz-linear-gradient(left,  rgba(255,255,255,0.27) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left,  rgba(255,255,255,0.27) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right,  rgba(255,255,255,0.27) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

			}
		}
		&[data-theme-version="dark"]{
			.coin-holding{
				&:after{
					background: -moz-linear-gradient(left,  rgba(5,5,5,0.27) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(left,  rgba(5,5,5,0.27) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to right,  rgba(5,5,5,0.27) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45050505', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

				}
			}
		}
		.market-line{
			.apexcharts-yaxis{
				transform: translateX(-10px);
			}
		}
		.current-chart{
			.apexcharts-datalabels-group{
				text:last-child{
					fill:$white;
				}
			}
		}
		.lineChart{
			margin-left:0;
			margin-right:auto;
		}
		.event-bx {
			.image-bx .info ul li i{
				margin-right:0;
				margin-left:10px;
			}
			.owl-nav{
				right: auto;
				left: 52px;
				direction: ltr;
			}
			.owl-dots {
				right: auto;
				left: 100px;
			}
		}
		.default-select.style-2 .btn:after{
			margin-left:0;
			margin-right:10px;
		}
		table.dataTable thead .sorting {
			background-position: center left 15px;
		}
		table.dataTable thead .sorting,
		table.dataTable thead .sorting_asc,
		table.dataTable thead .sorting_desc,
		table.dataTable thead .sorting_asc_disabled,
		table.dataTable thead .sorting_desc_disabled{
			background-position: center left;
		}
		
		.profile-card .title span{
			padding-right:0;
			padding-left:30px;
		}
		.skill-progress .progress-bar:after{
			right:auto;
			left:0;
		}
		.profile-card .form-group{
			.input-icon .form-control{
				padding-left: 0px;
				border-radius:$radius;
				padding-right: 70px;
			}
			.input-group-prepend{
				left: auto;
				right: 8px;
			}
		} 
		.search-dropdown{
			border-right:0;
			border-left:1px solid $border-color;
		}
		.new-arrival-content.text-center .price{
			float:none;
		}
		.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
			margin-right: 0;
			margin-left: 8px;
		}
		.pull-right{
			float:left;
		}
		.new-arrival-content .price{
			float:left;
		}
		.btn-icon-left{
			float:right;
			margin:-0.5rem -1.188rem -0.5rem 0.75rem;
		}
		.noUi-target{
			direction:rtl;
		}
		.noUi-horizontal .noUi-handle{
			right:0;
		}
		.noUi-vertical{
			.noUi-pips-vertical{
				left: -20px;
			}
			.noUi-value-vertical{
				padding-left: 0;
				padding-right: 25px;
			}
		}
    }

// }
@media only screen and (max-width: 1350px) and (min-width: 1200px){
    [direction="rtl"] {
		.content-body {
			margin-right: 17rem;
		}
	}
}