/* HTML: <div class="loader"></div> */
.p-colorpicker-overlay-panel {
  z-index: 10000 !important;
}

.p-colorpicker-preview {
  cursor: pointer;
  border: none;
  width: 2rem;
  border-radius: 50%;
  height: 2rem;
}

.barber-loader {
    width: 40px;
    height: 60px;
    position: relative;
    margin: auto;
    margin-top: 15rem;
    margin-bottom: 15rem;

}
.barber-loader::before,
.barber-loader::after {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--primary);
    clip-path: polygon(0 0,100% 0, 100% 67%,50% 67%,50% 34%,0 34%);
    animation: sp7 2s infinite;
  }
  .barber-loader::after {
    --s:-1,-1;
  }
  @keyframes sp7 {
      0%,
      10%  {transform:scale(var(--s,1)) translate(0,0)        rotate(0deg)}
      33%  {transform:scale(var(--s,1)) translate(0,-20px)    rotate(0deg)}
      66%  {transform:scale(var(--s,1)) translate(10px,-20px) rotate(-90deg)}
      90%,
      100% {transform:scale(var(--s,1)) translate(10px,-10px) rotate(-90deg)}
  }