[data-theme-version="dark"] {
    &[data-layout="vertical"]{
		&[data-sidebar-position="fixed"]{
			.header{
				border-color:$d-border;
			}
		}
	}
    .header {
		//background-color:$dark-card;
		border-color:$d-border;
		
    }

    .header-left {
        .dashboard_bar{
			color:$white;
		}
    }

    .header-right {
		.search-area{
			.form-control{
				background:$dark-card;
			}
			.input-group-text{
				border:0;
				background:$dark-card;
				a{
					color:#fff;
				}
			}
		}
		.notification_dropdown .nav-link{
			background: transparent!important;
			.badge{
				border-color:$dark-card;
			}
		}
        .dropdown {
            .nav-link {
				color: $white;
                &:hover {
                    color: $white;
                }
				svg path{
					fill:#d4d4d4;
				}
            }
        }	
		.header-profile > a.nav-link .header-info{
			border:0;
		}
    }
	
	.nav-header .hamburger .line{
		background:$white!important;
	}
	.menu-toggle .nav-header .nav-control .hamburger .line {
		background-color: $white !important;
	}
	.notification_dropdown .dropdown-menu-end .all-notification{
		border-color:$d-border;
	}
}