.items-table{

	 tbody{
		
		tr{
			padding:0;
			border-radius: $radius;
			position: relative;
			box-shadow: 0 1px 0 0 #f8f8f8;
			@include transitionMedium;
			.dropdown{
				opacity:0;
			}
			&.active,
			&:hover{
				box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
				z-index: 1;
				.dropdown{
					opacity:1;
				}	
			}
			th,
			td{
				border: 0;
				padding:18px 8px;
			}
		}
	}
	@include respond('phone') {
		width:500px;
	}	
}

.star-review{
	i{
		font-size:16px;
		&.text-gray{
			color:#d9d9d9;
		}
	}
	span{
		color:$black;
	}
}


// Form Head
.form-head{

	.breadcrumb{
		background:transparent;
		padding:0;
	}
	.search-area{
		max-width:300px;
		border-radius: 0;
		@include respond('laptop') {
			max-width:250px;
		}
		@include respond('phone') {
			max-width:250px;
		}
		.form-control{
			border: 0;
			height: 56px;
			padding: 10px 15px;
			font-size: 16px;
			background:#f1f1f1;
			border-radius:0;
			@include respond('laptop') {
				height: 41px;
				padding: 10px 15px;
				font-size: 13px;
			}
		}
		.input-group-append .input-group-text{
			background:#f1f1f1;
			padding: 0 5px  0 30px;
			@include respond('laptop') {
				padding: 0 5px  0 30px;
			}
			i {
				font-size: 20px;
				color:$body-color;
				@include respond('laptop') {
					font-size: 18px;
				}
			}
		}
	}
	.dropdown {
		.btn{
			
			i{
				transform: scale(1.3);
				-moz-transform: scale(1.3);
				-webkit-transform: scale(1.3);
				-ms-transform: scale(1.3);
				-o-transform: scale(1.3);
				display: inline-block;
			}
		}
		&:after{
			color:var(--primary);
			font-size:25px;
		}
	}
	/* .btn i{
		line-height: 1;
		transform: scale(1.3);
		display: inline-block;
		margin-right: 5px;
	} */
	/* .btn-outline-primary{
		border-color:#eee;
		&:hover{
			border-color:var(--primary);
		}
	} */
	& > *{
		@include respond('phone-land') {
			margin-right:5px !important;
			margin-bottom:10px;	
			vertical-align: baseline;
			margin-left:0 !important;
		}
	}
	@include respond('phone-land') {
		display:block !important;
	}
}

.btn-link{
    font-weight: 500;
    font-size: 16px;
	@include respond('laptop') {
		font-size: 14px;
	}
}
.deznav .copyright{
	font-size:14px;
	padding: 0 45px 60px;
	margin-top: 40px;
	color: #969BA0;
	margin-bottom: 40px;
	
	p{
		margin-bottom:10px;
	}
	strong{
		display:block;
	}
	
	@include respond('laptop') {
		padding:0 25px 60px;
	}
	@include respond ('phone-land'){
		padding-bottom: 60px;
	}
}
.widget-buyer {
    .media {
        img {
            width: 30px;
        }
        .media-body {
            h5 {
                font-size: 14px;
                margin-bottom: 0px;
            }
            p {
                font-size: 13px;
            }
        }
    }
}
.activity_overview{
	.nav-tabs{
		border-bottom:1px solid rgba(255,255,255,0.2) !important;
	}
	.custom-tab-1 .nav-link{
		color:rgba(255,255,255,0.5);
		padding-left: 0;
		padding-right: 0;
		margin-right: 30px;
		
		&:hover,
		&.active{
			background:transparent;
			color:#fff;
			border-color: #fff;
		}
	}
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent!important;
    opacity: 0.9;
}

.chart-link {
    @include respond('phone') {
        display: flex;
        align-items: center;
    }
    @include respond('tab-land') {
        display: flex;
        align-items: center;
    }
    @include respond('desktop') {
        display: flex;
        align-items: center;
    }
    .week-link {
        display: inline-block;
        // @include respond('tab-land'){
        //     display: flex;
        // }
    }
    a {
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        margin-right: 10px;
        i {
            font-size: 13px;
            &.text-primary {
                color: var(--primary);
            }
            &.text-muted {
                color: #DAE2F3!important;
            }
        }
    }
    .form-control {
        max-width: 97px;
        display: inline-block;
    }
}

.card-tabs {
    .nav-tabs {
		border-bottom: 0px;
		background: transparent;
		padding: 0;
		border-radius: 0;
		flex-wrap: unset;
			
        .nav-link {
            border-radius:0;
		    padding: 12px 20px;
			font-weight: 500;
			font-size:14px;
			border:1px solid $border-color;
			color:var(--primary);
            &.active {
                background: $light;
            }
			@include respond('laptop') {
				padding: 8px 15px;
				font-weight: 400;
				font-size: 13px;
			}
        }
		&.tabs-lg{
			.nav-link {
				padding:23px 30px;
				font-size:16px;
				@include respond('phone-land') {
					padding:10px 15px;
					font-size:14px;
				}	
			}
		}	
    }
	&.icontabs{
		.nav-tabs{
			background:transparent;
			.nav-item{
				.nav-link{
					height:42px;
					width:42px;
					line-height:42px;
					text-align:center;
					background:rgba($gray,0.6);
					color:$white;
					margin-right:15px;
					border-radius:	50px;
					padding:0;
					&.active{
						background:$warning;
					}
					i{
						font-size:25px;
						line-height:42px;
					}
				}
			}
		}
	}
}

.revenue-chart .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series{
	display:flex !important;
	margin-right: 30px !important;
	margin-bottom: 30px !important;
}
.revenue-chart-bar{
	margin-bottom:-30px;
	
	@include respond('phone') {
		margin-bottom: 20px;
		float: none;
		padding-left: 20px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.revenue-chart-bar{
		margin-bottom: 20px;
		float: none;
		padding-left: 20px;
	}
}
#activeUser {
    height: 215px!important;
}
#activeUser {
    height: 180px!important;
}
span#counter {
    font-size: 30px;
    font-weight: 700;
    color: var(--primary);
}
.social-graph-wrapper {
    text-align: center;
    padding: 20px;
    position: relative;
    color: $white;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
	
    &.widget-facebook {
        background-color: $facebook;
    }
    &.widget-twitter {
        background-color: $twitter;
    }
    &.widget-linkedin {
        background-color: $linkedin;
    }
    &.widget-googleplus {
        background-color: $google-plus;
    }
    .s-icon {
        font-size: 24px;
        position: relative;
        // top: 0.625rem;
        padding: 0 10px;
    }
}
.recent-stats i{
    font-size: 11px;
    position: relative;
    top: -1px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	#lineChart_3Kk{
		height: 140px !important;
	}
}
/* add menu sidebar */
.add-menu-sidebar{
	background: var(--primary); 
	padding: 40px 20px 20px;
    border-radius: 0;
	position:relative;
    margin: 50px 50px 30px;
	@include respond('laptop') {
		 margin: 50px 25px 30px;
	}
	@include respond('phone') {
		 margin: 20px 25px 30px;
	}
	
	p{
		font-size:16px;
		line-height:1.4;
		font-weight:300;
		color:#fff;
	}
	&:after{
		position:absolute;
		background-image:url('../images/dots.svg');
		background-repeat:no-repeat;
		bottom:10px;
		right:20px;
		content:"";
		height:30px;
		width:25px;
	}
}

.donut-chart-sale {
	position:relative;
	small {
		font-size: 16px;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		display: flex;
		align-items: center;
		top: 0;
		justify-content: center;
		font-weight: 600;
	}
}
.profile-bx{
	.profile-image{
		background-image:url('../images/circle.png');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		img{
			margin: 18px;
			width: 118px;
		}
	}
}

 //search-area
.search-area{
	width: 300px;
	.form-control{
		background:#EFEFEF;
		border-width:0px;
		padding-left: 20px;
		min-height: 55px;
		border-radius:0;
		border-top-left-radius: 60px;
		border-bottom-left-radius: 60px;
		&::placeholder{
			color:#757575;
		}
	}
	.input-group-text{
		background:#EFEFEF;
		border-width:0px;
		border-top-right-radius: 60px;
		border-bottom-right-radius: 60px;
		padding-left: 0;		
		padding-right: 20px;
		i{
			font-size:25px;
		}
		
	}
	@include respond('laptop'){
		.form-control{
			min-height:45px;
		}
		.input-group-text i{
			font-size:18px;
		}
	}
	@include respond('tab-port'){
		width:200px;
	}
 }
 
 //donut chart
 .donut-chart-sale{
	z-index: 1;
	.circle {
		height: 50px;
		width: 50px;
		z-index: -1;
		content: "";
		position: absolute;
		border-radius: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	small {
		font-size: 14px;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		display: flex;
		align-items: center;
		top: 0;
		justify-content: center;
		font-weight: 600;
	}	
}
 
 .min-w24{
	min-width:24px;
 } .min-w30{
	min-width:30px;
 }
 .min-w32{
	min-width:32px;
 }
 .min-w42{
	min-width:42px;
 }
 .min-w46{
	min-width:46px;
 }
 .min-w50{
	min-width:50px;
 }
.line-chart-demo{

} 

.form-group.style-1{
	.form-control{
		border-color:transparent;
		margin-right: -45px;
		z-index: 0;
		color:$black;
		background:#F0F0F0;
		border-radius:$radius;
	}
}

.previous-transactions{
	tbody{
		tr{
			td:first-child{
				width:100px;
			}
		}
	}
}


//star rating
.rating-widget .rating-stars ul li i {
    font-size: 25px !important;
}


//border-card
.border-card{
	h2{
		font-size:54px;
		@include respond ('tab-land'){
			font-size:42px;
		}
		@include respond ('phone'){
			font-size:35px;
		}
	}
	.position{
		font-size:18px;
		@include respond ('tab-land'){
			font-size:14px;
		}
	}
	.cd-icon{
		height:70px;
		width:70px;
		line-height:70px;
		min-width:70px;
		text-align:center;
		border-radius:$radius;
		@include respond ('tab-land'){
			height: 50px;
			width: 50px;
			min-height:50px;
			line-height: 46px;
			min-width: 50px;
			svg{
				width: 25px;
				height:25px;
			}
		}
	}
	.line{
		height:4px;
		border-radius:$radius;
		width:95%;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}


.heart {
    width: 60px;
    height: 60px;
    display: inline-block;
    background: url('../images/like.png');
    cursor: pointer;
    margin: -25px -15px;
}
.heart-blast {
    background-position: -1680px 0;
    transition: background 1s steps(28);
}


.profile-bx{
	@include respond ('tab-land'){
		border-right:1px solid $border-color;
	}
	@include respond ('phone-land'){
		border-right: 0;
	}
}

.primary-icon{
	path{
		fill:var(--primary);
	}
}
.text-justify{
	text-align:justify;
}
.list-style{
	li{
		list-style-type:unset;
	}
}
.fst-italic{
	font-family:italic;
}
#stackedChart{
	.apexcharts-bar-series.apexcharts-plot-series  .apexcharts-series path {
	  clip-path: inset(0% 0% 0% 0% round 20px);
	}

}
.ps--active-y>.ps__rail-y{
	width:2px;
}
 .search-dropdown{
	.btn{
		&:hover{
			color:unset;
		}
	}
 }
 .default-select{
	&.style-1{
		.btn{
			border-color:var(--primary)!important;
		}
		i{
			color:var(--primary);
		}
	}
 }
 .around-map{
	iframe{
	    width: 100%;
		height: 250px;
		border-radius:$radius;
	}
 }
 .table-responsive .table.card-table tbody .odd td, .table-responsive .table.card-table tbody .even td{
	border-bottom:none;
 }
 .eye{
	&.style-1{
		top:52px;
		@include respond('tab-land'){
			top:56px;
		}
		@include respond('phone-land'){
			top:42px;	
		}
	}
	top: 45px;
	position: absolute;
	right: 20px;
	
	@include respond ('laptop'){
		top:37px;
	}
	@include respond('tab-land'){
		top:37px;
	}
}

.custom-dropdown {
	.drop-select-btn.btn {
		border-color: var(--primary) !important;		
		&.show{
			color: #7e7e7e;
		}
	}
	
}