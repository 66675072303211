[data-theme-version="dark"] {
    .footer {
		background-color:$dark-card;
        .copyright {
            background-color: $d-bg;

            a {
                color: $white;
            }
        }
    }
}