[data-theme-version="dark"]{
	.card {
        background-color: $dark-card;
		box-shadow:none;
    }
	.dropdown-menu {
        background-color: $dark-card;
		box-shadow: 0px 0px 0px 1px rgba(255, 255, 255,0.1);
		.dropdown-item {
			color:#777777;
			&.selected,
			&.selected.active,
			&.active,
			&:hover, 
			&:focus {
				background-color: $dark-card;
				color: $white;
			}
		}
    }
	a{
		color:$white;
	}
	.text-primary{
		color:$white!important;
	}
	.btn-link g [fill]{
		fill: #fff;
	}
	.btn-light:active, 
	.btn-light:focus, 
	.btn-light:hover{
		color:#000;
	}
	.form-control {
        background-color:$dark-card;
        border-color: $d-border;
        color: $white;
    }
	.modal-content{
		background:$dark-card;
	}
	.modal-footer,
	.modal-header{
		border-color: $d-border;
	}
	.close{
	    font-weight: 400;
		color: $white;
		text-shadow: none;
		
		&:hover{
			color: $white;
		}
	}
	.star-rating .product-review,
	.new-arrival-content .item{
		color: $white;
	}
	.custom-dropdown .dropdown-menu{
		border-color: $d-border;
	}
	.widget-stat .media > span{
	    background: rgba($white,0.15);
	    border-color: $d-border;
		color:$white;
	}
	.plus-minus-input .custom-btn{
		background: $d-bg;
	    border-color: $d-border;
	}
	.dropdown-divider,
	.size-filter ul li{
		border-color: $d-border;
	}
	.custom-select{
	    border-color: $d-border;
		color: $d-ctl;
		background:$d-bg;
	}
	.nav-tabs{
		border-color: $d-border;
	}
	.mail-list .list-group-item.active i {
		color: $white;
	}
	hr{
		border-color: $d-border;
	}
	.grid-col{
		background:$d-bg;
	}
	
	.noUi-target{
		border-color:$d-border;
		border-radius: 8px;
		box-shadow: none;
		
		.noUi-connects{
			background:$d-border;
		}
	}
	.noUi-marker-large,
	.noUi-marker{
		background:$d-border;
	}
	.input-group-text{
		background:$dark-card;
		color:$body-color;
		border-color:$d-border;
	}
	.note-editor.note-frame{
		border-color:$d-border;
		.btn{
			color:$white;
		}
		.note-editing-area .note-editable{
			color:$white;
		}
	}
	.widget-media .timeline .timeline-panel{
		border-color:$d-border; 
	}
	.notification_dropdown .dropdown-menu-right .all-notification{
		border-color:$d-border; 
	}
	#user-activity .nav-tabs .nav-link{
		border-color:$d-border; 
        &.active{
            background:$d-bg;
            color:$white;
        }
	}
	.list-group-item-action{
		color:$body-color;
	}
	.list-group-item-action:focus, 
	.list-group-item-action:hover, 
	.list-group-item-action:focus{
		background-color:$d-bg; 
		border-color:$d-bg; 
	}
	.list-group-item.active{
		color:$white;
		border-color:var(--primary);
		&:focus, 
		&:hover, 
		&:focus{
			background-color:var(--primary); 
			border-color:var(--primary); 
			color:$white;
		}
	}
	.swal2-popup{
		background:$dark-card;
	}	
	.form-head .btn-outline-primary{
		border-color:$d-border;
	}
	.form-head .btn-outline-primary:hover{
		border-color:var(--primary);
	}
	.review-tab.nav-pills li a.nav-link.active{
		background:transparent;
	}
	.new-arrival-content {
		 h4 {
			a{
				color:$white;
			}
		 }
	}
	.text-black{
		color:$white!important;
	}
	.abilities-chart .ct-chart .ct-label{
		fill:$white;
	}
	.morris_chart_height text tspan{
		fill:$white;
	}
	.btn-link{
		color:$white;
	}
	.order-bg{
		background:$d-bg;
	}
	.detault-daterange .input-group-text{
		background:$d-bg;
		color:$white;
	}
	.dataTablesCard{
		background-color:$dark-card;
	}
	.compose-content .dropzone{
		background:$d-bg!important;
		border-color:$d-border;
		.dz-message .dz-button{
			color:$white;
		}
	}
	.daterangepicker{
		background:transparent;
		border-color:$d-border;
		.calendar-table{
			border-color:transparent;
			background:$d-bg;
			.table-condensed{
				td{
					&:hover{
						background-color:var(--primary);
						color:$white;
					}
				}
			}
		}
        &:after{
            border-bottom: 6px solid $d-bg;
        }
	}
	.daterangepicker .drp-buttons{
		border-color:$d-border;
	}
	.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span{
		border-color:$body-bg;
	}
	button.cancelBtn.btn.btn-sm.btn-inverse, .btn-group-sm > button.cancelBtn.btn.btn-inverse{
		background-color:$d-bg;
	}
    
    .daterangepicker select.hourselect
    , .daterangepicker select.minuteselect
    , .daterangepicker select.secondselect
    , .daterangepicker select.ampmselect{
            background: $d-bg;
            border: 1px solid $d-border;
            color:$white;
    }
    
	.daterangepicker td.off, 
	.daterangepicker td.off.in-range,
	.daterangepicker td.off.start-date, 
	.daterangepicker td.off.end-date{
		background-color:$d-bg;
		&:hover{
			background-color:var(--primary);
			color:$white;			
		}
	}
	.app-fullcalendar{
		.fc-button{
			background-color:$d-bg;
			border-color:var(--primary);
			color:$white;
			text-shadow:none;
			&:hover,&.fc-stat-hover{
				background-color:var(--primary);
			}
		}
	}
	.swal2-popup .swal2-styled:focus {
		outline: 0;
		box-shadow: 0 0 0 2px $d-border, 0 0 0 4px var(--rgba-primary-1);
	}
	.dd-handle{
		border-color:$d-border;
	}
	.menu-toggle .deznav .metismenu li > ul{
		background:$dark-card;
	}
	.header-right .notification_dropdown .nav-link{
		border-color:$d-border;
	}
	.nav-tabs .nav-link{
		&:hover,&.active{
			border-color:var(--primary);
		}
	}
	.clockpicker-popover .popover-content{
		background-color:$dark-card;
	}
	.clockpicker-plate{
		background-color:$d-bg;
	}
	.clockpicker-popover .popover-title{
		background-color:$d-bg;
		color:$white;
	}
	.form-wizard .nav-wizard li .nav-link span{
		background-color:$d-bg;
	}
	.form-wizard .nav-wizard li .nav-link:after{
		background:$d-bg;
	}
	.form-wizard .nav-wizard li .nav-link.active:after,.form-wizard .nav-wizard li .nav-link.done:after{
		background:var(--primary);
	}
	.form-wizard .nav-wizard li .nav-link.active span
	,.form-wizard .nav-wizard li .nav-link.done span{
		background:var(--primary);
	}
	.check-switch .custom-control-label:after
    , .check-switch .custom-control-label:before{
        border-color:var(--primary);
    }
    .fc-unthemed .fc-today{
        background:$d-bg;
    }
    .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-popover .fc-header{
        background: $d-border;
    }
    .picker__box{
        background:$d-bg;
        .picker__button--clear
        , .picker__button--close
        , .picker__button--today{
            background:$dark-card;
            color:$white;
            &:hover{
                &:before{
                    color:$white;
                }
            }
        }
    }
    .picker{
        color:#999;
    }
    .dtp > .dtp-content{
        background:$d-bg;
    }
    .dtp table.dtp-picker-days tr > td > a{
       color: #68686a; 
       &.selected{
           color:$white;
       }
    }
	.order-request tbody tr{
		border-color:$d-border;
	}
	.card-list li{
		color:$white;
	}
	.card-bx .change-btn:hover{
		color:var(--primary);
	}
	.invoice-card{
		&.bg-warning{
			background-color:#5b3c1f!important;
		}
		&.bg-success{
			background-color:#2a6729!important;
		}
		&.bg-info{
			background-color:#4c276a!important;
		}
		&.bg-secondary{
			background-color:#1c3e52!important;
		}
	}
	.user-list li{
		border-color:$dark-card;
	}
	.toggle-switch{
		color:$white;
	}
	.bar-chart{
		.apexcharts-text tspan{
			fill:#969ba0;
		}
		line{
			stroke:$d-border;
		}
	}
	
	table.dataTable thead th, table.dataTable thead td{
		border-color:$d-border!important;
	}
	.form-check .form-check-input{
		//background:transparent;
		border-color:$d-border;
		
	}
	
	.dataTables_wrapper .dataTables_paginate span .paginate_button.current,
	.dataTables_wrapper .dataTables_paginate span .paginate_button:hover{
		background:var(--primary);
		color:$white!important;
	}
	
	.light.btn-primary,
	.light.btn-danger,
	.light.btn-warning,
	.light.btn-success,
	.light.btn-info{
		color:$white;
		svg path{
			fill:$white;
		}
	}
	.bg-white{
		background:$dark-card!important;
	}
	.bg-light.text-primary{
		color:var(--primary)!important;
	}
	table.dataTable{
		color:#b5b5b5;
		tbody td {
			background: $dark-card !important;
		}
	} 
	.contact-icon{
		border-color:$d-border;
	}
	.profile-card{
		.title{
			span{
				background:$dark-card;
			}
			&:after{
				background:$d-border;
			}
		}
		.form-group{
			.form-control{
				background:$d-bg;
				color:$white;
				border-color:$d-border;
				&:hover,
				&:focus,
				&:active{
					border-color:var(--primary);
				}
			}
			.bootstrap-select.form-control button{
				border-color:$d-border!important;
				&:after{
					color:$white;
				}
			}
			textarea{
				background:$d-bg;
			}
			.input-group-prepend .input-group-text{
				border:0;
				i{
					color:$white;
				}
			}
		} 
	}
	.activity-card,.profile-bx{
		border-color:$d-border;
	}
	.sharp-lg{
		svg path{
			fill:$white;
		}
	}
	.progress-icon{
		border-color:$d-border;
	}
	.portfolios-card > div{
		background:$d-bg;
	}
	.page-titles{
		background:$d-bg;
		.breadcrumb li.active a{
			color:$white;
		}
	}
	.light.btn-primary g [fill]{
		fill:$white;
	}
	.profile-info h4.text-primary {
		color: $white !important;
	}
	.profile-tab .nav-item .nav-link:hover, .profile-tab .nav-item .nav-link.active{
		color:$white;
	}
	.pagination-primary .page-item .page-link{
		color:$white;
	}
	.app-fullcalendar .fc-button.fc-state-hover{
		background:var(--primary)!important;
	}
	.new-arrival-content .price{
		color:$white;
	}
	.btn-outline-primary{
		color:$white;
	}
	.btn.tp-btn-light.btn-primary g [fill]{
		fill:$white;
	}
	.btn-icon-left.text-primary{
		color:var(--primary)!important;
	}
	.custom-tab-1 .nav-link:focus,
	.custom-tab-1 .nav-link:hover,
	.custom-tab-1 .nav-link.active{
		color:$white;
	}
	.bg-light{
		h1,h2,h3,h4,h5,h6{
			color:$black!important;
		}
	}
	.default-tab .nav-link:focus,
	.default-tab .nav-link:hover,
	.default-tab .nav-link.active,
	.default-tab .nav-link i,
	.custom-tab-1 .nav-link i,
	.nav-pills.light .nav-link.active,
	.nav-pills.light .show > .nav-link{
		color:$white;
	}
	.table-bordered th, .table-bordered td,
	.table th, .table td{
		border-color:$d-border;
	}
	mark, .mark{
		background:var(--primary);
	}
	.dd-item>button{
		color:$white;
	}
	.form-wizard .nav-wizard li .nav-link span{
		color:$white;
	}
	.dataTables_wrapper .dataTables_length,
	.dataTables_wrapper .dataTables_filter,
	.dataTables_wrapper .dataTables_info,
	.dataTables_wrapper .dataTables_processing,
	.dataTables_wrapper .dataTables_paginate{
		color:$white;
	}
	.dataTables_wrapper .dataTables_paginate .paginate_button{
		color:$white!important;
	}
	.dataTables_wrapper .dataTables_paginate.paging_simple_numbers > .paginate_button{
		color:var(--primary);
	}
	.search-dropdown{
		border-color:$d-border;
	}
	.line-chart{
		line{
			stroke:$d-border;
		}
		.apexcharts-yaxis{
			tspan{
				fill:#a9a9a9;
				font-size: 14px;
			}
		}
	}
	table.dataTable.display tbody tr:hover td{
		background:$d-bg!important;
	}
	.bg-light{
		background-color: $d-bg !important;
	}
	.table thead th{
		color:$white;
	}
	.ck.ck-editor .ck-content{
		background-color:$d-bg!important;
	 }
	 .ck.ck-editor .ck.ck-toolbar{
		background-color:$d-bg;
	 }
	 .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred{
		border-color:$d-border;
	 }
	 button.ck.ck-button.ck-off:hover{
		background-color:unset;
	 }
	 .border-end,
	 .border-start{
		border-color:$d-border!important;
	 }
	 .btn-close{
		background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill='white' d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
	}
	.badge-light{
		color:$black;
	}
	.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid, .fc-scrollgrid, table,
	.fc-theme-standard td, .fc-theme-standard th{
		border-color:$d-border;
	}
	.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start{
		background-color:$dark-card;
		border-color:$d-border;
	}
	.new-arrival-content p{
		color:$white;
	}
	.logo-compact{
		filter:brightness(10);
	}
	.toggle-switch .form-check-input:checked{
		background:var(--rgba-primary-5)
	}
	.admin-settings{
		 .bootstrap-select .btn{
			border-color:$border-color !important;
			background: $white !important;

		 }
		  .dropdown-menu{
			background-color:$white!important;
			box-shadow:0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
			
			.dropdown-item{
				background-color:$white!important;
				color:#514e5f!important;
				
				&:hover{
					background-color:#e9ecef!important;
				}
				
				&.active{
					background-color:var(--rgba-primary-1)!important;
					color:var(--primary)!important
				}
			} 
		  }
	}
	#preloader,
	.sk-three-bounce{
		background-color:$d-bg;
	}
	.dropdown-dot{
		svg {
			path{
				stroke : $white;
			}
		}
	}
	.dzu-dropzone{
		background-color: $dark-card !important;
		.dzu-inputLabel {
			background-color: $dark-card !important;
		}
	}
}
