.rbc-header {
    width:120px !important
}

.rbc-day-slot {
    min-width: 120px !important;
}

.nav-back {
    padding: 0 !important;
    margin-bottom: 1rem !important;
    margin-right: 1rem !important;
}
.nav-next {
    padding: 0 !important;
    margin-left: 2rem;
    margin-bottom: 1rem !important;
margin-left: 1rem !important;
}

@media (max-width: 992px) {
    .lg-picker-card {
      display: none;
         }

    }
     @media (min-width: 992px) {
        .sm-picker-card {
          display: none;
            }
        }

.widthOverride {
    width: 100% !important;
    margin-right: 1rem !important;
    /* right:5rem;
    left: -5px !important; */
    z-index: 1000;
    }


.calendar-body {
    height: 85vh
}

.calendar-style {
    height: 69vh;
}

@media (min-height: 800px) {
    .calendar-body {
        height: 73vh;
    }
    .calendar-style {
        height: 59vh;
    }
}

@media (min-height: 1000px) {
    .calendar-body {
        height: 85vh;
    }
    .calendar-style {
        height: 72vh;
    }
}

@media (min-height: 1300px) {
    .calendar-body {
        height: 78vh;
    }
    .calendar-style {
        height: 68vh;
    }
}


/* HTML: <div class="loader"></div> */
.loader {
    width: 40px;
    height: 60px;
    position: relative;
    margin: auto;
    margin-top: 15rem;
    margin-bottom: 15rem;

}
.loader::before,
.loader::after {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--primary);
    clip-path: polygon(0 0,100% 0, 100% 67%,50% 67%,50% 34%,0 34%);
    animation: sp7 2s infinite;
  }
  .loader::after {
    --s:-1,-1;
  }
  @keyframes sp7 {
      0%,
      10%  {transform:scale(var(--s,1)) translate(0,0)        rotate(0deg)}
      33%  {transform:scale(var(--s,1)) translate(0,-20px)    rotate(0deg)}
      66%  {transform:scale(var(--s,1)) translate(10px,-20px) rotate(-90deg)}
      90%,
      100% {transform:scale(var(--s,1)) translate(10px,-10px) rotate(-90deg)}
  }


  .calendar-block-out-background {
    /* background-color: #e5e5f7; */
    opacity: 0.4;
    background: repeating-linear-gradient( -45deg, #cbccda, #c6c7d3 2px, #e5e5f7 3px, #e5e5f7 15px );
    left: 0% !important;
    width: 100% !important;
    z-index: 1 !important;
  }

  .full {
    left: 0% !important;
    width: 100% !important;
    z-index: 2 !important;

  }

  .app {
    z-index: 3 !important;
  }