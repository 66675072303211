.network-chart{
	margin-top:-90px;
	margin-bottom:-40px;
	@include respond ('mid-desktop'){
		margin-top:0;
	}
	@include respond ('tab-land'){
		margin-top:-90px;
	}
	@include respond ('phone'){
		margin-top: -30px;
		margin-bottom: -40px;
	}
}
.network-header{
	position:relative;
	z-index:3;
}