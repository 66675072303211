@import "./../../abstracts/variable";



[data-theme-version="dark"] {

	--nav-headbg: #{$dark-card};
	--sidebar-bg: #{$dark-card};
	--headerbg: #{$d-bg};
	--bs-body-bg:#{$dark-card};
    background: $d-bg;
    color: $d-ctl;
	
	
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white !important;
    }
    a.link {
        color: $d-ctd;
    }
    a.link:focus,
    a.link:hover {
        color: $info;
    }

    a {
        &:hover {
            color: $white;
        }
    }
	
    //Generating border classess
    @each $value in $borders {
        @if $value == "" {
            .border {
                border: 1px solid $d-border !important;
            }
        } @else {
            .border-#{$value} {
                border-#{$value}: 1px solid $d-border !important;
            }
        }
    }


}