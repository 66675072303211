.search-job{
	.form-control {
		width: 50%;
		@include respond ('phone-land'){
			width:100%;
		}
	}
}
.sharp-lg {
    min-width: 50px;
    height: 50px;
	line-height:48px;
    min-height: 50px;
    text-align: center;
	@include respond ('laptop'){
		min-height: 40px;
		height: 40px;
		min-width: 40px;
		line-height: 38px;
	}
}
.search-row{
	.title{
		font-size:20px;
		font-weight:600;
	}
	.sub-title{
		font-size:18px;
	}
	&:hover{
		box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
	}
	@include respond ('laptop'){
		.title{
			font-size:17px;
		}
		.sub-title{
			font-size:16px;
		}
	}
}
.aroundYou{
	border-right:1px solid $border-color;
	@include respond ('tab-port'){
		border-right:0;
	}
}
.like-btn {
    display: inline-block;
    padding-left: 0;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: FontAwesome;
}
.like-btn input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
	display:block;
}
.checkmark {
    height: 40px;
	text-align: center;
	line-height:38px;
    width: 40px;
	display: block;
    border: 1px solid var(--primary);
    border-radius: 20px;
	@include respond ('laptop'){
		height:32px;
		width:32px;
		line-height:29px;
	}
}
.like-btn .checkmark:before {
    content: "\f097";
    font-size: 20px;
    font-weight: 400;
    color: var(--primary);
	@include respond ('laptop'){
		font-size: 17px;
	}
}

.like-btn input:checked~.checkmark {
    background-color: var(--primary);
}

.like-btn input:checked~.checkmark:before {
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    left: 0;
    top: 0;
    border-radius: 20px;
    color: #fff;
    padding: 3px 6px;
}
.search-dropdown{
	.custom-dropdown .dropdown-menu{
		width:100%;
		
	}
}